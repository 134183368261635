const yesNoCardsData = [
  {
    primaryText: `You might blame yourself or feel that you did something to provoke the assault`,
  },
  {
    primaryText: `You might wonder whether you somehow gave ‘mixed signals’ about wanting to have sex`,
  },
  {
    primaryText: `You might have intense feelings of rage, fear, grief or hatred towards the perpetrator`,
  },
  {
    primaryText: `You may experience overwhelming shock or disbelief – did this really happen to me?`,
  },
  {
    primaryText: `It might be hard to get the assault out of your mind`,
  },
  {
    primaryText: `You may have nightmares or flashbacks`,
  },
  {
    primaryText: `You might feel like crying a lot`,
  },
  {
    primaryText: `You might have physical symptoms like nausea, headaches, or stomach problems`,
  },
  {
    primaryText: `You might feel numb and detached`,
  },
  {
    primaryText: `You might have trouble being intimate with a partner`,
  },
  {
    primaryText: `You might be scared about contracting a sexually transmitted infection or getting pregnant`,
  },
  {
    primaryText: `You might be unsure if what you experienced was really sexual assault`,
  },
  {
    primaryText: `You may feel worried or anxious about seeing the person who assaulted you in communal College spaces`,
  },
  {
    primaryText: `You may be worried the person who assaulted you will spread rumours about you at College`,
  },
]

const TwoColumnCheckboxScreenOptions = [
  `General Practitioner / family doctor`,
  `Friends or family`,
  `Police`,
  `Uni counsellor`,
  `Sexual Assault Service`,
  `Safer Community Program`,
  `Senior Staff at College`,
  `Tutor at College`,
  `External Advisor`,
  `No one or not sure`,
]

const ThreeColumnCheckboxScreenOptions = [
  `Somebody grabbed or touched you in a sexual way when you didn’t want them to.`,
  `You thought you were agreeing to one thing during sex, but something else ended up happening that you didn’t agree to.`,
  `You started off wanting to have sex but then changed your mind, but the person you were having sex with kept going anyway.`,
  `You felt pressured or blackmailed into having sex when you didn’t want to.`,
  `You had sex with someone in a position of power over you (e.g. a tutor) because you were worried something bad would happen if you said no (e.g. failing an assignment).`,
  `You had sex with someone because you needed a place to stay or a job.`,
  `Someone removed a condom during sex without your consent (stealthing).`,
]

export const victimSexFlowData = [
  {
    template: `ContentScreen`,
    id: `2.1.1-consent-content-1`,
    data: {
      textLeft: `<h2>Consent</h2><p>Sex is supposed to be fun and enjoyable, but sometimes sexual experiences happen that you don’t feel good about and didn’t want.</p><p>Legally, any unwanted behaviour of a sexual nature that causes fear, humiliation or intimidation is considered to be <span>sexual assault</span>.</p><p>In other words, if someone does something to you that you didn’t or couldn’t consent to (provide free agreement), it’s a crime.</p>`,
      textRight: `<p>Sexual assault includes:</p>
        <ul>
        <li>Rape (with or without physical violence);</li>
        <li>Forcing you to have oral sex (give or receive);</li>
        <li>Penetrating you with fingers or other objects;</li>
        <li>Other forms of unwanted sexual activity such as being touched or groped, being filmed during sex, being forced to watch pornography;</li>
        <li>Any other uninvited sexual behaviour that makes you feel uncomfortable, harassed or afraid.</li>
        </ul>`,
    },
  },
  {
    template: `ContentScreen`,
    id: `2.1.1-consent-content-2`,
    data: {
      textLeft: `<h2>Consent</h2><p>You can’t give free agreement if you’re being <span>threatened</span>, or if you're <span>asleep</span>, <span>drunk</span> or <span>affected by substances.</span> </p><p>We often hear about ‘No means no!’ but sometimes consent is complicated. You might feel like you can’t say no, or that saying no would be unsafe, so instead you say nothing. Silence is <span>not the same as consent</span>.</p><p>Just because you didn’t say ‘No’ or didn’t fight back doesn’t mean you consented.</p>`,
      textRight: `<p>On this website we are talking about sexual experiences you might have had as an adult (over the age of 16) with someone you were not in a relationship with.</p><p>If you have experienced sexual abuse as a child (under 16), this website might help you <a href='http://www.blueknot.org.au' >www.blueknot.org.au</a>.</p><p>If you have had an unwanted sexual experience in a relationship, you can visit the relationships section of this website once you’ve finished this module.</p>`,
    },
  },
  {
    template: `CheckboxScreen`,
    id: `2.1.1.1-unwanted-sexual-experiences-checkbox`,
    data: {
      heading: `Unwanted sexual experiences`,
      popUp: {
        tall: true,
        type: `feedback`,
        heading: `Feedback`,
        msg1: `<h2>Answer</h2>
        <p>Actually, all of these
        experiences are forms of
        sexual assault. It is <span> never
        OK </span> for someone to touch
        you in a sexual way
        without consent, and it is <span>never OK</span> for someone to
        pressure you into having
        sex. If someone is using
        your situation as a way to 
        force you to have sex with
        them, that’s called
        <span>coercion</span> and it’s illegal. You are in charge of your own body and you have a right to have your sexual decisions respected.</p>`,
      },
      textRight: `<p>Most people hear the words ‘sexual assault’ and think of rape and being physically forced or held down. However, there are also lots of grey areas when it comes to having sex. Below are some common things that can happen.</p>
      <span>Which ones do you think count as sexual assault?</span>
        `,
      columns: 3,
      options: ThreeColumnCheckboxScreenOptions,
    },
  },
  {
    template: `YesNoCardsScreen`,
    id: `2.1.1.2-feelings-yes-no-cards`,
    data: {
      textLeft: `<h2>Feelings</h2><p>You may have many complicated feelings when you have been sexually assaulted or had an unwanted sexual experience.</p><p>Please tick if you are feeling any of these:</p>`,
      cardsData: yesNoCardsData,
    },
  },
  {
    template: `ContentScreen`,
    id: `2.1.1.2-feelings-content`,
    data: {
      textLeft: `<h2>Feelings</h2><p>These feelings and reactions are common and normal.</p><p>Remember, your feelings might change over time as well. It may take days, weeks or months for some feelings to catch up with you. There is no ‘right’ or ‘wrong’ way to feel; every response is valid.</p><p>The important thing to remember is that it is never your fault. Nobody deserves to be assaulted or to be pressured into doing something they don’t feel comfortable with.</p><p>With time, it’s likely you will feel better, but in the meantime, this website will suggest some things you can do to help take care of yourself.</p>`,
      textRight: `<p>If you are feeling persistently down or low, anxious or panicky, or are experiencing flashbacks or reliving the sexual assault, it’s important to see someone for help.</p><p>Depression, anxiety and stress are very common. They can have a negative impact on many areas of your life, but they can also be delt with very effectively with the right support.</p><p>Remember, if you are thinking about hurting yourself, please call Lifeline on <a href:'tel:131114'>13 11 14</a> to speak to someone confidentially.</p>`,
    },
  },
  {
    template: `VideoScreen`,
    id: `2.1.1.2-video-1`,
    data: {},
  },
  {
    template: `VideoScreen`,
    id: `2.1.1.3-video-2`,
    data: {
      double: true,
    },
  },
  {
    template: `CheckboxScreen`,
    id: `2.1.1.4-talking-about-it-checkbox`,
    data: {
      heading: `Talking about it`,
      textRight: `<p>Here is a list of people you might choose to talk to after an unwanted sexual experience or sexual assault. Tick the boxes below to find out how these people or services can help</p>
      <span>Please choose all the options that apply.</span>`,
      internationalPopUp: true,
      columns: 2,
      options: TwoColumnCheckboxScreenOptions,
    },
  },
  {
    template: `TalkingFeedbackScreen`,
    id: `2.1.1.4-talking-about-it-feedback`,
    data: {
      sections: [
        {
          title: `General Practitioner / family doctor`,
          body: `
          <p>A trusted GP can help in several ways if you have been sexually assaulted. If the assault happened within the last 72 hours, the GP can provide emergency contraception and can organise testing to make sure you haven't contracted any sexually transmitted infections (STIs). Some GPs are trained to conduct a forensic assessment (if you wish to report the assault). If they can't do this themselves, they should be able to refer you to someone who can.</p>
          <p>Whether or not you want to report the assault (or if it happened longer than 72 hours ago), the GP can still help. A good GP can be a safe person to talk to. They can provide you with a medical certificate if you need time off from work. They can also refer you to a mental health professional (including helping you access 10 free sessions if you have a Medicare card) to deal with stress, trauma, or other difficult feelings.</p>
          <p>If you have become pregnant as a result of the sexual assault, the GP can also help discuss your options. Remember, everything you discuss with the GP is confidential.</p>`,
        },
        {
          title: `Friends or family`,
          body: `
          <p>Sometimes just telling someone about a sexual assault can help, even if you don't want the person to do anything about it. A supportive friend or family member should listen non-judgementally and offer assistance without being pushy. You may find that others around you have had similar experiences and can understand what you’re going through. Even if they haven’t, their support can still be really helpful.</p>
          <p>A friend or family member can be a good person to come with you if you have been recently assaulted and need medical care, or want to report the assault to police. They can also make sure that you practice good self-care to help manage stress, anxiety, or other negative feelings.</p>
          `,
        },
        // {
        //   title: `Police`,
        //   body: `
        //   <p>You may decide to report a sexual assault to the police. If you choose to do this, and the sexual assault happened within the past 72 hours, the police may ask you to undergo a forensic medical examination (FME). The purpose of a FME is to collect and document physical evidence of the crime.</p>
        //   <p>A doctor or nurse from the Victorian Institute of Forensic Medicine will conduct the FME; they are specialised in doing this type of examination, and can speak to you about any medical concerns that you have. The test may involve things like a blood test, vaginal or anal swabs, and an examination of any bruises or cuts. At the end of the exam, the evidence may be used by police to assist them in identifying and charging the person who assaulted you.</p>
        //   <p>You are also likely to be asked to give a statement to police. If an officer’s gender makes you uncomfortable, you can request that a different office take your statement if possible. You do not have to make a statement if you don't want to, even if you made an initial report to police. If you decide not to, the case won't proceed in court. Making a statement to police can take several hours, and be emotionally difficult. It can be good to have a support person with you at the time.</p>`,
        // },
        {
          title: `Police`,
          body: `
          <p>The best place to report a sexual assault is your closest Sexual Offences and Child Abuse Investigation Team (SOCIT). SOCITs are specialised police units that are trained to respond to sexual assault. SOCITs prioritise the needs of victims and aim to make their investigations a victim-led process. They will focus on ensuring your safety and wellbeing; your ability to regain control of your circumstances; help you access support and information, navigate the criminal justice system and access other services appropriate to your need.</p>
          <p>This is what might happen if you decide to make a report or talk to police:
          </p>
          <p>The police should offer you counselling and advocacy support from a Centre Against Sexual Assault (CASA). If your sexual assault has occurred recently, they will accompany you to a Crisis Care Unit, where you will be supported by a CASA counsellor. You can also talk to CASA without having any contact with police at all, if you don’t want to.</p>
          <p>If the sexual assault happened within the past 72 hours, the police may ask you to undergo a forensic medical examination (FME). It is your choice whether to do this or not. The purpose of a FME is to collect and document physical evidence of the crime. A doctor or nurse from the Victorian Institute of Forensic Medicine will conduct the FME; they are specialised in doing this type of examination and can speak to you about any medical concerns that you have. The test may involve things like a blood test, vaginal or anal swabs, and an examination of any possible physical injuries. If you are not sure you want to proceed with an investigation, you can have a medical examination anyway, and make up your mind at a later date.</p>
          <p>At the end of the exam, the evidence may be used by police to assist them in identifying and charging the person who assaulted you. You are also likely to be asked to give a statement to police. You will have the right to request that the statement is taken by an officer who you feel comfortable with. You do not have to make a statement if you don't want to, even if you made an initial report to police. If you decide not to, the case won't proceed in court.</p>
          <p>If you choose not to make a statement, or for the investigation to proceed, you may still give information about the assault to the police, so they can register your information, check it against other reported offences and - if you wish - contact you at a later date should the perpetrator be reported again.
          </p>
          <p>If the assault happened more than 72 hours ago, or even a long time ago, you can still make a report. Detectives often investigate assaults reported weeks, months and even years after an assault has occurred. In most cases they will take a statement from you and may compare it with historical police data. Criminal charges can still be laid against the offender, even after a long time has passed. If you make a statement and an investigation ensues, you can withdraw at any time.</p>
          <p>There are contact details for the 28 SOCITs in Victoria at the end of this website.</p>
          <p>Be aware that reporting, and making a statement, can take a long time, and be an emotional experience. It can be really helpful to have a friend go with you for support. Investigations can also take some time, so try to build a support network. This may include a CASA counsellor, friends and/or family, who you can talk to about your experience.</p>

          `,
        },
        {
          title: `Uni counsellor`,
          body: `
        <p>Universities provide free, confidential, short term counselling to currently enrolled domestic and international students. For international students especially, this may be the most easily accessible person you can talk to, because it is on campus. You can either call to book an appointment or book online.</p>
        <p>A counsellor or psychologist is a great person to talk to if you are unsure about your relationship and whether it is healthy or not as they are impartial and confidential. They will not tell the university about any issues you may be having, this is not their role. They may recommend that you seek more specialised services if they feel that you are in danger or you need some help. They will be able to help you access these services while you are in their office if you feel unsafe doing this on your own. Click on the link below to book an appointment online.</p>
        <p><a rel='noopener noreferrer' target='_blank' href='https://services.unimelb.edu.au/counsel/home#appointments'>Book an appointment online</a></p>
        <p>For Monash students,  <a rel='noopener noreferrer' target='_blank' href='https://www.monash.edu/health/counselling'>click here</a> for more information on the campus counselling service and to book online.</p>
        <p>For RMIT students, <a rel='noopener noreferrer' target='_blank' href='https://www.rmit.edu.au/students/support-and-facilities/student-support/counselling'>click here</a>.</p>`,
        },
        {
          title: `Sexual Assault Service`,
          body: `
          <p>The Centres Against Sexual Assault (CASAs) in Victoria look after people who have experienced any type of sexual assault. They provide up to 10 sessions of support, counselling and advocacy that is confidential and free. Their counselling is based on a feminist model and most of their clients are women, but they also support men and gender-diverse individuals.</p>
          <p>The closest Centre to The University of Melbourne is CASA House, which is located in the QV Building. CASA House provides: 24 hour crisis support and access to medical attention and legal information following an assault; individual counselling and advocacy support; support groups; 24 hour telephone counselling and support; information and referral.</p>
          <p>Even if it has been months or years after an assault, you can still ring CASA House for support. Unfortunately, the waiting times to get a counselling appointment can be quite long, although the telephone service is always available. Details for these services are at the end of this website.</p>`,
        },
        {
          title: `Safer Community Program`,
          body: `
          <h4>University of Melbourne</h4>
          <p>The Safer Community Program is the University's support service for people who have experienced inappropriate, concerning or threatening behaviour, including sexual harassment and sexual assault. They are the University’s central point of inquiry and reporting and offer a respectful and confidential place to seek support and advice.</p>
          <p>You can connect with the Safer Community Program in different ways: via email, telephone, or using the appointment system (see <a rel="noopener noreferrer" target="_blank" href="https://safercommunity.unimelb.edu.au/about-us#contact">here</a> for contact options). You may also be referred to the Safer Community Program from other services within the University. For after-hours support, you can contact University security, who will refer you to the on-call well-being officer if necessary.</p>
          <p>The Safer Community Program provides services to students across all campuses of the University. At present, face-to-face services have ceased due to COVID-19, however, normally, they can organize to meet with you in person at your location.</p>
          <p>When you contact the Safer Community Program, in most instances you will have a conversation with an advisor. This is to help you decide what the appropriate response might be for your particular circumstances. Advisors are trained to respond to unwanted sexual experiences, and you can choose whether to speak with a male or female advisor. The conversation may cover topics like: your safety, your support requirements, and what referrals might help you. The advisor will also discuss whether you wish to make a report to Victoria Police or to the University. They can assist you in navigating the courts and complaints process and help with academic or practical support (e.g. special consideration for your studies, housing, financial help).</p>
          <p>The Safer Community Program respects your agency and decision-making and will be guided by your wishes wherever possible (as long as there are no legal requirements or University policies preventing this). The Safer Community Program will continue to engage with you until you are linked in with University or external agencies and are feeling confident to engage with formal reporting processes.</p>
          <h4>Safer Community (RMIT)</h4>
          <p>If you or someone you know has experienced unwanted or threatening behaviour - including sexual harassment or assault - RMIT’s Safer Community can help. When you contact Safer Community, they will listen, try to understand what’s important to you, explain and explore options (you decide which is most appropriate), always consider your well-being and discuss with you if they need to take steps to ensure the safety of the community.</p>
          <h4>bSAFE & Safer Community Unit (Monash)</h4>
          <p>When you contact Safer Community, they will listen, try to understand what’s important to you, explain and explore options (you decide which is most appropriate), always consider your well-being and discuss with you if they need to take steps to ensure the safety of the community.</p>
                    `,
        },
        {
          title: `Senior Staff at College`,
          body: `
          <p>You may wish to speak to a Senior Staff member about an unhealthy relationship. Senior Staff will respect your decisions about what to do next and will be guided by your wishes wherever possible.</p>
          <h3>Deputy Principal</h3>
          <p>The Deputy Principal is responsible for the College’s Pastoral Care Program. The Deputy Principal in particular can help with:</p>
          <ul>
            <li>Listening non-judgementally to your concerns</li>
            <li>Applying for Special Consideration.</li>
            <li>Referrals to medical/support services including Safer Community (Unimelb/RMIT/Monash) and Counselling and Psychological Services (CAPS).</li>
            <li>Safeguards to minimise contact between you and the other person(s).</li>
            <li>Assistance to make a Police report.</li>
            <li>Support with applying for a Personal Safety Intervention Order.</li>
          
          </ul>
          <p>They can support you with accessing any of the services listed above and on this site.</p>
          <h3>Safe Coordinator (Dean of Studies)</h3>
          <p>Safe Coordinators are experienced staff members appointed by each College. The SAFE Coordinator at JCH is the Dean of Studies. Like tutors, the Dean of Studies is trained in Mental Health First Aid and as a First Responder to disclosures of sexual assault. The Dean of Studies can be present if you would like support with approaching the Deputy Principal or Principal. They are responsible for</p>
          
          <ul>
            <li>prioritising the agency of the complainant by involving them in decisions about the College’s response to their complaint;</li>
            <li>balancing the needs and wishes of a complainant against the obligation to provide a safe environment for all staff, visitors, students and residents;</li>
            <li>providing ongoing support and guidance to complainants and reporters of Sexual Misconduct, and respondents to Sexual Misconduct complaints;</li>
            <li>ensuring the appropriate and timely appointment of Safe Contact Persons and External Advisers;</li>
            <li>providing training for their College community, Safe Contact Persons on preventing and responding to Sexual Misconduct; and</li>
            <li>advising their College on policies, programs, initiatives and strategies recommended to enhance legislative compliance and promote student and staff access, equity and diversity.</li>
            
          </ul>
          <p>Like the Deputy Principal, the Dean of Studies can support you with accessing services and exploring the options available to you.</p>
          <h3>Principal</h3>
          <p>The Principal will act in response to serious disciplinary matters within the College. You can direct formal complaints to the Principal who will engage an external investigator to report back with recommendations. If you are unsure about making a formal complaint, you might wish to consider speaking to the Deputy Principal, the SAFE Coordinator, A SAFE Contact person or an External Advisor first.</p>
          <p><em>When, how and why is the College obliged to act?</em></p>
          <ul>
          <li>The College will balance the need for you (the complainant) to have agency with community wellbeing.</li>
          <li>The College cannot investigate hearsay, rumour or innuendo and will require a formal complaint and investigation to determine a course of action.</li>
          <li>The College may require a student or students to depart residence while an investigation is undertaken resulting in a formal response. In this case it will provide ongoing pastoral support.</li>
          <li>The College will contact the Police if there is a perceived immediate or ongoing risk to the health, safety and wellbeing of any person.</li>
          
          </ul>
          <p>
            <strong>The contact details for the Deputy Principal, the SAFE Coordinator (Dean of Studies) and the Principal can be found in the Action Plan section of this website under “Talk to Someone at College”</strong>
          </p>
        `,
        },
        {
          title: `Tutor at College`,
          body: `
          <p>Resident tutors know what it’s like to live in College and know what it’s like to be a student. They also know your JCH peers and the wider College community. You may find that this makes it easier to talk to a tutor about an unwanted sexual experience (you may also find this makes it harder to talk to a tutor. This is okay). </p>
          <p>Part of a resident tutor’s role is to listen non-judgmentally and help you navigate different resources available to you. Many students find that they naturally gravitate towards one tutor they have a good rapport with - this may not always be your floor tutor or the tutor of your subjects.</p>
          <p>All tutors are trained in Mental Health First Aid and as First Responders to disclosures of sexual assault.</p>
          <p>Some tutors have also been trained and appointed as SAFE Contact Persons. SAFE stands for “sexual misconduct prevention; advice and advocacy; fair treatment; and equality and equity”. For more details about this, you can read the College’s    <a href="https://jch.unimelb.edu.au/file/14928/797" rel='noopener noreferrer' target='_blank'>Sexual Misconduct Policy and Procedure</a> and <a href="https://jch.unimelb.edu.au/file/14927/796" rel='noopener noreferrer' target='_blank'>Discrimination, Bullying
          and Victimisation Policy and Procedure </a>.
          </p>
          <p>If you do decide to have a chat with a tutor they can organise to meet in a quiet, private space in or outside of the College.
          </p>
          <p>Tutors will respect your decisions and will be guided by your wishes wherever possible.
          </p>
          <p>Tutors, including SAFE Contact Persons, can also help you reach out to the Deputy Principal who is the SAFE Coordinator and looks after the College’s Pastoral Care Program. They can join you for the initial meeting if you’d like some extra support.
          </p>
          <p>There are two key instances when a tutor is unable to keep complete confidentiality and will let you know if they need to contact the Deputy Principal on your behalf:
          </p>
          <ul>
          <li>When they consider you or others to be in immediate danger, or there is an ongoing risk to others in College.</li>
          <li>When you or the other person(s) are <a href="https://jch.unimelb.edu.au/file/7385/1902" rel='noopener noreferrer' target='_blank'>Under 18</a> and a sexual assault is disclosed.</li>
          </ul>
          `,
        },
        {
          title: `External Advisor`,
          body: `
          <p>External SAFE Advisors can provide guidance on options to report on behaviour from a partner or ex-partner that has made you feel afraid or unsafe. They can advise on supports available to you and assist you to engage with the University, the Police, medical and external counselling services as appropriate.</p>
          <p>As part of their support role, external SAFE Advisors may also be involved in the complaint handling process. In some cases this may involve conciliation - a process where you can be supported to reach a mutually agreed-upon solution with the other person. (This will only happen when it is safe to do so).</p>
          <p>The University of Melbourne Colleges collectively employ the external SAFE Advisors on a retainer basis. This means that external SAFE Advisors are not JCH Staff - they are independent, impartial professional contacts who understand (but operate outside of) the College setting.</p>
          <p>Usually a JCH SAFE contact person, the SAFE Coordinator, or the Deputy Principal assists you to make contact with one of the External Advisors in the event that you are considering making a formal complaint and would like extra support.</p>
          <p>You can also contact them directly. External SAFE Advisors will keep your conversations confidential (unless you or others are in danger).</p>
          <p><strong>External Advisor contact details can be found in the Action Plan section at the end of this website under the “Talk to Someone at College” section</strong></p>

            `,
        },
        {
          title: `No one or not sure`,
          body: `
          <p>Talking to someone may not seem like the right thing to do, and that's okay. Not everyone wants to share an experience of sexual assault, especially if it happened a long time ago and they don't want to do anything about it. However, if you do start to feel yourself experiencing flashbacks, or feeling anxious, sad, depressed or stressed out, it's important to seek help early.</p>
          <p>Sometimes the impacts of trauma can show up years after the experience, so keeping a close eye on your mental health is a good idea.</p>
          <p>In the meantime, it might be helpful to keep a journal. Sometimes, the first person you need to tell is yourself, and writing things down might help. Remember to be kind to yourself and know that there are people and supports who will respond when you are ready.</p>
          `,
        },
      ],
    },
  },
  {
    template: `ContentScreen`,
    id: `2.1.1.4-Content`,
    data: {
      textLeft: `<h2>Talking about it</h2><p>Talking about your experience can be helpful. Research suggests that it might help avoid or reduce some of the mental health impacts. However, remember that <span>you</span> are in control of how much you disclose or share with others.</p>`,
      textRight: `<p>You do not have to disclose the <span>details</span> about a sexual assault or unwanted sexual experience in order to get help.</p><p>A supportive listener should allow you to disclose <span>only as much as you feel comfortable.</span></p>`,
    },
  },
]
